import { useEffect, useRef } from 'react';
import { BasicForm, InputMainContainer, InputFilterContainer, DateFilterContainer, SelectContainer } from '../../../../components/Form/BasicForm';
import { BasicModal } from '../../../../components/modal/BasicModal'
import { FaPlus, FaPrint, FaMinus, FaDownload, FaStickyNote, FaEdit, FaCheckDouble, FaFileExcel, FaExchangeAlt } from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';
import { useModal } from '../../../../hooks/useModal';
import { ModalConfirm } from '../../../../components/modal/ModalConfirm';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import download from 'js-file-download';
import { Card } from '../../../../components/cards/Card';
import { GoAlert } from 'react-icons/go';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import Popper from '@material-ui/core/Popper';
import { TutorialButton } from '../../../../components/TutorialButton/TutorialButton';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import { ItensShippingRelation, ObjetoVazio, StockAllDetail, CostDataShippingRelation } from "../../../../types/Types"
import { useReactToPrint } from 'react-to-print';
import VirtualizedGrid from '../../../../components/table/VirtualizedGrid';
import { styled } from '@mui/material/styles';
import '../../../../components/table/globalInputs.scss';
import { GridModal } from '../../../../components/modal/GridModal';
import axios from 'axios';
import { useState , State, Downgraded, none } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import socket from '../../../../utils/socketConnection';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import { useHandleErrors } from '../../../../hooks/useHandleErrors';
import XLSX from 'xlsx';

import { 
    GridFilterItem,
} from '@mui/x-data-grid';


const StyledPopper = styled(Popper)({
    "& .MuiAutocomplete-listbox": {
      '& li':{
        fontSize: 'clamp(1.1rem, 1vw, 1.3rem)',
      }
    }
  });

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CheckboxesTags(props: any) {
  const { item, applyValue, options:tag_options } = props;

  const handleFilterChange = (event: any, value: any) => {
    applyValue({ ...item, value: value });
  };

  return (
    <Autocomplete
      multiple
      options={tag_options}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option.title}
      defaultValue={item.value}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Tags" variant="standard" sx={{'& .MuiChip-root':{fontSize: 'clamp(1rem, 1.1vw, 1.2rem)'}}} />
      )}
      PopperComponent={StyledPopper}
      onChange={handleFilterChange}
    />
  );
}


const modalOptions = {
    palette:{
      tableHeader: {
        main: '#F7CE3E',
        dark: '#ac902b',
        light: '#f8d764',
        contrastText: '#000'
      },
    },
}

const modalColumns = [
    { 
      headerName: "Sku",
      field: "sku", 
      type: 'string',
      headerAlign: 'center',
      flex: 1,
      renderCell: (rowData: any) => {
        return (
            <span>{rowData.row.sku}</span>
        )
      }
    },
    { 
        headerName: "Produto",
        field: "product", 
        type: 'string',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span title={rowData.row.product}>{rowData.row.product}</span>
            )
        }
    },
    { 
        headerName: "Nota Fiscal",
        field: "number_nf", 
        type: 'string',
        headerAlign: 'center',
        flex: 1,
        render: (rowData: any) => {
            return (
                <span>{rowData.row.number_nf !== null ? rowData.row.number_nf : "N/A"}</span>
            )
        }
    },
    { 
        headerName: "Custo Anterior",
        field: "last_cost", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{rowData.row.last_cost.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    },
    { 
        headerName: "Custo Atual",
        field: "cost_product", 
        type: 'number',
        headerAlign: 'center',
        flex: 1,
        renderCell: (rowData: any) => {
            return (
                <span>{rowData.row.cost_product.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            )
        }
    }
]
interface IDepositTransactions {
    depositIdOrigin: number| undefined,
	depositOrigin: string | undefined,
	depositIdDestiny: number| undefined,
	depositDestiny: string | undefined
}
interface IDataShipping {
    scheduling: string | undefined ,
    description: string| undefined ,
    schedulingQty: number| undefined ,
    volumesQty: number | undefined ,
    completed: boolean,
    LOADED: boolean
}
interface IDataCards {
    totalQtyToShip: number,
    totalQtyAvailable: number,
    totalQtyVariable: number,
    totalShippingWeight: string,
    maxShippingWeight: number,
    maxCubicMeters: number,
    totalCubicMeters: string,
}
interface INFData{
    fail_products: Object[],
    success_products: Object[],
    revision: ObjetoVazio
}
interface IDepositRelation{
    id: string,
    descricao: string,
    ativo: boolean
}


export const AllShippments = (props: any) =>{
    const handleValidateStatus = useHandleErrors();
    const data: State<ItensShippingRelation[]> = useState<ItensShippingRelation[]>([]);
    const dataDeposits: State<any[]> = useState<any[]>([]);
    const showButtons: State<boolean> = useState<boolean>(false)
    const {ModalData, showHideModal} = useModal();
    const schedulingItens: State<object> = useState<object>({});

    const rowsSelected  = useState<string[]>([])

    const showFinishBtn: State<boolean> = useState<boolean>(false)
    const showDescModal: State<boolean> = useState<boolean>(false);
    const showStockModal: State<boolean> = useState<boolean>(false);
    const productsData: any = useState<object[]>([]);
    productsData.attach(Untracked);

    const rowsToAdd = useState<number>(0);
    const showTabsModal: State<boolean> = useState<boolean>(false)
    const dataTabsModal: State<INFData | ObjetoVazio> = useState<INFData | ObjetoVazio>({})


    const rowsFinished: State<ObjetoVazio> = useState<ObjetoVazio>({});
    const showFailData: State<boolean> = useState<boolean>(false);
    const failData: State<object[]> = useState<object[]>([]);
    const showModal: State<boolean> = useState<boolean>(false);
    const modalData: State<CostDataShippingRelation[]> = useState<CostDataShippingRelation[]>([]);
    const itensToPrint: State<object[]> = useState<object[]>([]);
    const componentRef = useRef(null);

    const itemsFinished: any = useRef(null)
    const inputs: any = useState(undefined)
    const dataShipping = useState<IDataShipping>({scheduling: undefined, description: undefined, schedulingQty: undefined, volumesQty: undefined, completed: false, LOADED: false});
    const dataCards = useState<IDataCards>({totalQtyToShip: 0, totalQtyAvailable: 0, totalQtyVariable: 0, totalShippingWeight: "0", maxShippingWeight: 0, maxCubicMeters: 0, totalCubicMeters: "0"});
    const depositsTransactions = useState<IDepositTransactions>({depositIdOrigin:undefined,depositOrigin:undefined,depositIdDestiny:undefined,depositDestiny:undefined})
    const depositsRelation = useState<IDepositRelation[]>([])

    const token: string | undefined = localStorage.getItem('token') || undefined;
    const axs = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: { 'Authorization': `Bearer ${token}` }
    })


    const checkVisibility = (column: string)=>{
        const storageColumnsData = localStorage.getItem('Relação dos Envios') ? localStorage.getItem('Relação dos Envios') : null;
        if(storageColumnsData !== null){
          const localData = JSON.parse(storageColumnsData);
          const shouldHide = localData.find((item: any)=> item.field === column);
          return shouldHide !== undefined ? !shouldHide.visibility : false
        }
        return false
    }

    const printTable: any = useReactToPrint({  
        content: () => componentRef.current,
        documentTitle: "Relação para Impressão",
    });

    const handlePrintTable = () => {
        const itens = data.attach(Downgraded).get().filter((value: ItensShippingRelation, index: number, array:ItensShippingRelation[])=>array.findIndex(t=>(t.sku === value.sku)) === index)//"Agrupamento" por sku
        const itensSorted = itens.sort((a: ItensShippingRelation, b:ItensShippingRelation) => { 
            if(a.product !== undefined && b.product !== undefined){
                if(a.product > b.product){
                    return 1
                }
                if(a.product < b.product){
                    return -1
                }
                return 0
            }else{
                return 0
            }
        })
        const processedItens = itensSorted.map((item: ItensShippingRelation) => {
            let product = item.product
            let sku = item.sku
            let quantity_shipped: any = 0
            let _id = item._id;
            let inventory_id: any = item.inventory_id ? item.inventory_id : "N/A"
            let inventoryIDQty = '';
            
            for(let elm of data.attach(Downgraded).get()){
                if(elm.sku === item.sku && elm.inventory_id !== item.inventory_id){
                    quantity_shipped += elm.quantity_shipped;
                    inventoryIDQty += `, (${elm.quantity_shipped})`
                    if(elm.inventory_id){
                        inventory_id += `, ${elm.inventory_id}` 
                    }else{
                        inventory_id += `, N/A` 
                    }
                }
                else if(elm.sku === item.sku){
                    quantity_shipped += elm.quantity_shipped
                }
            }
            if(inventoryIDQty !== ''){
                quantity_shipped = `${quantity_shipped} ${inventoryIDQty}`
            }

            return {product, sku, quantity_shipped, inventory_id, _id}
        })
        itensToPrint.set(processedItens)
        if(processedItens.length > 0){
            setTimeout(()=>printTable(),2000)
        }
    }

    /***
     * Fazer a Call para puxar os depósitos do Bling, criar rota para pegar os dados no melibeat
     * Após fazer a call popular select do modal
     * Validar se select está alterando com os dados correios
     * Duplicar select para o Destino
     * Validar Requisição e testar funcionamento.
     */

    const fetchResource = (query:any={}) => axs.get<any[]>(`/bling/deposits`, {params: query}).then((r: any) => {
        return r.data;
    }).catch((err: any)=>{
        handleValidateStatus(err.response)
        return []
    })

    const {DarkMode, } = useDarkMode();

    let resData: any
    let idx: number
    let filterTimeout: any = useRef(null);
    let inputsFill: Map<string,any>;
    let finishedFill: Map<string,any>;

    useEffect(() => {
        socket.emit("fulfillment:getStepOne", {'id': props.itemId});
        socket.on("responseData", (result: any) => {
            switch(result.operation){
                case 'fulfillment:getStepOne':
                    const itens = result.data.itens.map((item: any)=>{
                        let tags = [];
                        if(item.composition) tags.push("Composição (Sim)")
                        else tags.push("Composição (Não)")
                        if(item.composition) tags.push("Composição (Sim)")

                        item.quantity_shipped > item.stockAllDetail.PR && item.stockAllDetail.PR > 0 
                        ? (item.quantity_shipped > item.stockAllDetail.SC && item.stockAllDetail.SC > 0 ? tags.push("Nota Fiscal Dupla (Sim)") : tags.push("Nota Fiscal Dupla (Não)")) : tags.push("Nota Fiscal Dupla (Não)")

                        item.tags = tags;
                        return item
                    })
                    data.set(itens);
                    dataShipping.batch(
                        (shipping)  =>  {
                            shipping.scheduling.set(result.data.scheduling)
                            shipping.description.set(result.data.description)
                            shipping.schedulingQty.set(Math.round(result.data.quantity))
                            shipping.volumesQty.set(Math.round(result.data.volume))
                            shipping.completed.set(result.data.completed.relation)
                            shipping.LOADED.set(true)
                        }
                    )
                    dataCards.batch(
                        (item)  =>  {
                            item.maxShippingWeight.set(result.data.shipping.max_weight)
                            item.maxCubicMeters.set(result.data.shipping.max_m3)
                        }
                    )
            
                    if(result.data.itens.length < 0){
                        toast.info("Adicione produtos para visualizar a tabela.");
                        const tooltip = (
                            <Tippy content={
                                <div style={{display: 'flex', flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: '1rem'}}>
                                    <strong>Quantidade Total: 0</strong>
                                    <strong>Quantidade Variável: 0</strong>
                                    <strong>Quantidade Adicional: 0</strong>
                                </div>
                                } interactive={true} maxWidth={'none'}>
                
                                <strong>{result.data.quantity}</strong>
                            </Tippy>
                        )
                        schedulingItens.set(tooltip)
                    }
                    //Inicializador e Populizer dos Inputs
                    inputsFill = new Map()
                    finishedFill = new Map()

                    result.data.itens.forEach((item: any)=>{
                        inputsFill.set(item._id, {
                            quantity_released: item.quantity_released,
                            quantity_shipped: item.quantity_shipped,
                            cost_product: item.cost_product,
                            observation: item.observation,
                            number_nf: item.number_nf,
                            printed: item.printed,
                            finished: item.finished,
                        })
                        if(item.finished){
                            finishedFill.set(item._id,item.finished)
                        }
                    })
                    
                    inputs.set(Object.fromEntries(inputsFill))
                    itemsFinished.current = Object.fromEntries(finishedFill)
                    handleChangeData()
                    break;
                case 'fulfillment:putFulfillment':
                    dataShipping.batch(
                        (shipping)  =>  {
                            shipping.scheduling.set(result.data.scheduling)
                            shipping.description.set(result.data.description)
                            shipping.schedulingQty.set(Math.round(result.data.quantity))
                            shipping.volumesQty.set(Math.round(result.data.volume))
                            shipping.completed.set(result.data.completed.relation)
                            shipping.LOADED.set(true)
                        }
                    )
                    handleChangeData()
                    toast.success("Dados Alterados!");
                    break;
                case 'fulfillment:putNewItem':
                    resData = result.data;
                    //Inicializador e Populizer dos Inputs
                    inputsFill = new Map()
                    finishedFill = new Map()
                    resData.forEach((item: any)=>{
                        inputsFill.set(item._id, {
                            quantity_released: item.quantity_released,
                            quantity_shipped: item.quantity_shipped,
                            cost_product: item.cost_product,
                            observation: item.observation,
                            number_nf: item.number_nf,
                            printed: item.printed,
                            finished: item.finished,
                        })
                    })
                    inputs.merge(Object.fromEntries(inputsFill))
                    data.merge(resData);
                    handleChangeData()
                    break;
                case 'fulfillment:putStatus':
                    props.resetPage()
                    toast.success("Seção de Relação Finalizada!");
                    break
                case 'fulfillment:putDeleteItem':
                    toast.success("Linhas Deletadas!");
                    const aux_data = data.attach(Downgraded).get();
                    const idItensDeleted = result.data.idItens
                    const filteredData = aux_data.filter((item: any)=> !idItensDeleted.includes(item._id))
                    data.set(filteredData)
                    handleChangeData()
                    break;
                case 'fulfillment:putCost':
                    toast.success("Linhas Alteradas!");
                    const resultData = result.data.data;
                    const auxiliary_data = data.attach(Downgraded).get().map((item: any) => {
                        let idx = resultData.findIndex((it: any) => it._id === item._id)
                        if(idx > -1){
                            item["cost_product"] = resultData[idx].cost_product
                            inputs[item._id].cost_product.set(resultData[idx].cost_product)
                        }
                        return item
                    });
                    modalData.set(result.data.data)
                    data.set(auxiliary_data)
                    showModal.set(true)
                    //handleChangeData()
                    break;
                case 'fulfillment:putItem':
                    switch(result.ref){
                        case 'sku':
                            let stock = 0;
                            let stockDetail: StockAllDetail = {
                                "PR": 0, "SP": 0, "SC": 0
                            };
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        let idxp = props.allSKUS.findIndex((item: any) => item.sku === resData.sku)
                                        stock = props.allSKUS[idxp].stockAll;
                                        stockDetail = props.allSKUS[idxp].stockAllDetail;

                                        item.sku.set(resData.sku);
                                        item.product.set(resData.product);
                                        item.cost_product.set(resData.cost_product);
                                        item.brand.set(resData.brand);
                                        item.origin.set(resData.origin);
                                        item.composition.set(resData.composition);
                                        item.gross_weight.set(resData.gross_weight);
                                        item.cubic_meter.set(resData.cubic_meter);
                                        item.category_bling.set(resData.category_bling);
                                        item.stockAll.set(stock);
                                        item.stockAllDetail.set(stockDetail);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.cost_product.set(resData.cost_product);
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'mlb':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.mlb_id.set(resData.mlb_id);
                                        item.variation_id.set(resData.variation_id);
                                        item.variation.set(resData.variation);
                                        item.inventory_id.set(resData.inventory_id);
                                        item.marketplace_fee.set(resData.marketplace_fee);
                                        item.cost_shipping_fee.set(resData.cost_shipping_fee);
                                        item.tax.set(resData.tax);
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'variation':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.variation_id.set(resData.variation_id);
                                        item.variation.set(resData.variation);
                                        item.inventory_id.set(resData.inventory_id);
                                        item.marketplace_fee.set(resData.marketplace_fee);
                                        item.cost_shipping_fee.set(resData.cost_shipping_fee);
                                        item.tax.set(resData.tax);
                                    }
                                )
                            }
                            break;
                        case 'quantity_released':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.quantity_released.set(resData.quantity_released);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.quantity_released.set(resData.quantity_released)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'quantity_shipped':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.quantity_shipped.set(resData.quantity_shipped);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.quantity_shipped.set(resData.quantity_shipped)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'cost_product':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.cost_product.set(resData.cost_product);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.cost_product.set(resData.cost_product)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'observation':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.observation.set(resData.observation);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.observation.set(resData.observation)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'number_nf':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.number_nf.set(resData.number_nf);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.number_nf.set(resData.number_nf)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'printed':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.printed.set(resData.printed);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.printed.set(resData.printed)
                                    }
                                )
                                handleChangeData()
                            }
                            break;
                        case 'finished':
                            resData = result.data;
                            idx = data.attach(Downgraded).get().findIndex((item) => item._id  === resData._id)
                            if(idx > -1){
                                data[idx].batch(
                                    (item)  =>  {
                                        item.finished.set(resData.finished);
                                    }
                                )
                                inputs[resData._id].batch(
                                    (inp: any) => {
                                        inp.finished.set(resData.finished)
                                    }
                                )
                                itemsFinished.current[resData._id] = resData.finished
                                handleFinishedButton()
                            }
                            break;
                        default:
                            break;
                    }
                    break;

                default:
                    return
            }
        })
        socket.on("responseError", (result) => {
            toast.error(result.message);
        })
        depositsRelation.set(fetchResource());
    }, [])

    const handleFinishedButton = () => {
        const values = Object.values(itemsFinished.current);
        const totalToFinish = data.length
        const finished = values.filter(it => it === true).length
        if(finished === totalToFinish && showFinishBtn.get() === false){
            showFinishBtn.set(true)
        }else if(finished !== totalToFinish && showFinishBtn.get() === true){
            showFinishBtn.set(false)
        }
    }


    const handleChangeData = async()=>{
        let finish:boolean = true;
        let sumQty: number = 0;
        let sumCubicMeters: number = 0;
        let sumWeight: number = 0;
        const finishedRows:ObjetoVazio = {...rowsFinished.attach(Downgraded).get()};
        let inputs_quantity_shipped:ObjetoVazio = {}

        for (let item of data.attach(Downgraded).get()){
            if(item.finished === false) finish = false;
            sumQty += item.quantity_shipped;
            sumCubicMeters += item.cubic_meter * item.quantity_shipped;
            sumWeight += item.gross_weight * item.quantity_shipped;
            finishedRows[item._id] = item.finished

            inputs_quantity_shipped[item._id] = item.quantity_shipped
        }

        const schedulingQtyTemp = dataShipping.schedulingQty.attach(Downgraded).get() !== undefined ? dataShipping.schedulingQty.get() : 0;
        let qtdVariable: number =  schedulingQtyTemp || 0 * 0.1
        let qtdAditional: number = schedulingQtyTemp || 0 * 0.2
        let qtdTotal: number = schedulingQtyTemp || 0 + qtdVariable + qtdAditional
        let qtyTotalVariable: number = Math.round(((sumQty / (dataShipping.schedulingQty.get() || 0)) - 1)*100)

        qtdVariable =  (qtdVariable === undefined || qtdVariable === Infinity || isNaN(qtdVariable)) ? 0 : qtdVariable;
        qtdAditional =  (qtdAditional === undefined || qtdAditional === Infinity || isNaN(qtdAditional)) ? 0 : qtdAditional;
        qtdTotal =  (qtdTotal === undefined || qtdTotal === Infinity || isNaN(qtdTotal)) ? 0 : qtdTotal;
        qtyTotalVariable = (qtyTotalVariable === undefined || qtyTotalVariable === Infinity || isNaN(qtyTotalVariable)) ? 0 : qtyTotalVariable;
        sumCubicMeters = (sumCubicMeters === undefined || sumCubicMeters === Infinity || isNaN(sumCubicMeters)) ? 0 : sumCubicMeters;
        sumWeight = (sumWeight === undefined || sumWeight === Infinity || isNaN(sumWeight)) ? 0 : sumWeight;
        sumQty = (sumQty === undefined || sumQty === Infinity || isNaN(sumQty)) ? 0 : sumQty;

        dataCards.batch(
            (item)  =>  {
                item.totalQtyToShip.set(sumQty)
                item.totalQtyAvailable.set((dataShipping.schedulingQty.get() || 0) - sumQty)
                item.totalQtyVariable.set(qtyTotalVariable)
                item.totalShippingWeight.set(sumWeight.toFixed(2))
                item.totalCubicMeters.set(sumCubicMeters.toFixed(2))
            }
        )
        rowsFinished.set(finishedRows)

        if(finish){
            showFinishBtn.set(true)
        }else{
            showFinishBtn.set(false)
        }
    }
    

    const handleSubmit = (evt:React.BaseSyntheticEvent)=>{
        let canFinish: boolean = true;
        let showToastFinished: boolean = true;
        evt.target.reset()
        evt.preventDefault();
        const tempSchedulingQty: number = dataShipping.schedulingQty.attach(Downgraded).get() || 0;
        if(data.get().length <= 0){
            toast.info("Não é possível finalizar um envio sem itens.")
            return
        }
        if(isNaN(tempSchedulingQty)){
            toast.info("O campo Qtd. Itens do Agendamento deve ser preenchido com valores numéricos");
            return
        }
        if(tempSchedulingQty <= 0){
            toast.info("O campo Qtd. Itens do Agendamento não pode ser 0");
            return
        }

        data.attach(Downgraded).get().forEach((item: any)=>{
            if(!item.finished){
                if(showToastFinished){
                    toast.info("Todos os itens devem estar finalizados.");
                    showToastFinished = false;
                }
                canFinish = false
            }
        })

        if(canFinish){
            if(props.activeUsers > 1){
                showHideModal({show: true, title: "Existem usuários ativos trabalhando neste envio. Gostaria de finalizar?", execute: (confirm: boolean)=> handleFinish(confirm)})
            }else{
                socket.emit("fulfillment:putStatus", {'id': props.itemId, "field": "relation", "value": true});
            }

        }
    }

    const handleItemFinished = async(evt: React.BaseSyntheticEvent, rowId: string)=> {
        evt.persist();
        const hidx = data.get().findIndex(item => item._id === rowId)
        if(hidx > -1){
            const row = data.get()[hidx]
            if(row.sku === undefined || row.sku === "" || row.sku === null ||
            row.product === undefined || row.product === "" || 
            row.mlb_id === undefined || row.mlb_id === "" || row.mlb_id === null ||
            row.variation === undefined || row.variation === "" || 
            row.inventory_id === undefined || row.inventory_id === ""){
                toast.info("Os campos SKU, Produto, Id do Anúncio, Variação, e Inventory ID devem estar preenchidos.");
                evt.target.checked = !evt.target.checked;
                return
            }
            if(row.quantity_shipped <= 0 || row.cost_product === undefined || row.cost_product <= 0){
                toast.info("Os campos Quantidade Enviada e Custo não podem ser 0.");
                evt.target.checked = !evt.target.checked;
                return
            }
            const processedData = [
                {
                    "field": "finished",
                    "value": evt.target.checked,
                }
            ]
            inputs[row._id].finished.set(evt.target.checked)
            rowsFinished[row._id].set(evt.target.checked)
            socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": processedData, "idItem": row._id, ref: 'finished'});
        }
    }

    const handleFinish = (confirm: boolean)=>{
        if(confirm){
            socket.emit("fulfillment:putStatus", {'id': props.itemId, "field": "relation", "value": true});
        }else{
            toast.info("Você escolheu não finalizar.")
        }
    }


    const handleSelection = (rows: string[])=>{
        rowsSelected.set(rows)
        if(rows.length > 0){
            if(!showButtons.get()){
                showButtons.set(true);
            }
        }else{
            if(showButtons.get()){
                showButtons.set(false);
            }
        }
            
    }

    const handleDelete = (value: boolean) =>{
        if(value){
            // executePut(`/fulfillment/${props.itemId}/deleteitem`, {"idItens": rowsToDelete}, 'deleteData');
            socket.emit("fulfillment:putDeleteItem", {'id': props.itemId, "idItens": rowsSelected.attach(Downgraded).get()});
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
            showButtons.set(false);
        }else{
            // setProductsData({products: {}, description: ''});
            showHideModal({show: false, title: ModalData?.title, execute: ModalData?.execute})
        }
    }

    const handleCellEdit = async(newValue: any, rowData: any, column: string)=>{
        toast.info("Processando...", {autoClose: 1000})
        if(column === "quantity_shipped"){
            if(newValue <= 0){
                toast.info("O valor do campo Quantidade Enviada não pode ser menor que 1")
                return;
            }
        }
        if(column !== "printed" && column !== "number_nf" && column !== "observation"){
            newValue = parseFloat(newValue);
        }
        const processedData = [
            {
                "field": column,
                "value": newValue,
            }
        ]
        socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": processedData, "idItem": rowData._id, ref: column});
    }

    const addRow = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        const tempRef = rowsToAdd.attach(Downgraded).get();
        if(tempRef <= 0){
            toast.info("Informe um valor ao campo Quantidade.")
            return 
        }else{
            let value = tempRef;
            rowsToAdd.set(0);
            socket.emit("fulfillment:putNewItem", {'id': props.itemId, "qty": value});
            // executePut(`/fulfillment/${props.itemId}/newitem`, {"qty": rowsToAdd[0]}, 'putAddRowData');
        }
    }

    const handleNF = (evt: React.BaseSyntheticEvent)=>{
        let generate: boolean = true;
        evt.preventDefault();
        const processedData:any = {
            idFulfillment: props.itemId,
            volumes: dataShipping.volumesQty.get(),
            products: []
        }
        const filteredData = data.attach(Downgraded).get().filter((item: any)=> rowsSelected.attach(Downgraded).get().some((item2)=> item._id === item2))
        filteredData.forEach((row: any)=>{
            if(row.sku !== undefined && row.quantity_shipped !== undefined && row._id !== undefined){
                processedData.products.push({
                    "sku": row.sku,
                    "qty": row.quantity_shipped,
                    "idItem": row._id
                })
            }else{
                generate = false;
            }
        })

        if(generate){
            toast.info("Processando...")
            axs.post<any>('/bling/nf/', processedData).then((r: any) => {
                if(r.status === 200){
                    const resultData = r.data;
                    const aux_data = data.attach(Downgraded).get();
                    aux_data.forEach((item: any)=>{
                        for(let sp of resultData.success_products){
                            if(item._id === sp.idItem){
                                item["number_nf"] = sp.numberNf
                                inputs[item._id].number_nf.set(sp.numberNf)
                            }
                        }
                    })

                    dataTabsModal.set(resultData)
                    showTabsModal.set(true)
                    data.set(aux_data)
                    handleChangeData()
                }
                else if(r.errorData){
                    if(r.errorData.fail_products.length > 0){
                        failData.set(r.errorData.fail_products);
                        showFailData.set(true);
                    }
                }
                else{
                    showButtons.set(true);
                    return
                }
            })
        }else{
            toast.info("Os valores SKU e Quantidade Enviada devem estar preenchidos.")
        }
        showButtons.set(false);
    }

    const handleRemoveItemModal = (idItem:number) =>{
        let hidx = productsData.attach(Downgraded).get().findIndex((item: any) => item.idItem === idItem)
        if(hidx > -1){
            productsData[hidx].set(none)
            toast.info("Item removido!");
        }
    }

    const handleEditItemModal = (idItem: number, value: number)=>{
        let hidx = productsData.attach(Downgraded).get().findIndex((item: any) => item.idItem === idItem)
        if(hidx > -1){
            productsData[hidx].qty.set(value)
        }
    }
    const closeFunction = ()=>{
        showButtons.set(true);
        showDescModal.set(false);
        showStockModal.set(false);
    }

    const handlePrint = (evt: React.BaseSyntheticEvent) =>{
        evt.preventDefault()
         const processedData: any[] = []
         data.attach(Downgraded).get().forEach((item: any)=>{
             if(rowsSelected.attach(Downgraded).get().includes(item._id)){
                 processedData.push({
                     "sku": item.sku, 
                     "title": (item.product ? item.product : 'N/A'), 
                     "mlb_id": item.mlb_id, 
                     "qty": Math.abs(item.quantity_shipped - item.printed),
                     "variation__id": item.variation_id,
                     "inventory_id": item.inventory_id,
                     "idItem": item._id
                })
            }
        })

        Untracked(productsData).set(processedData);
        showDescModal.set(true);
    }

    const handleTransferStock = (evt: React.BaseSyntheticEvent) =>{
        evt.preventDefault()
        const dataDeposit = depositsTransactions.attach(Downgraded).get()
        const depositsRel = depositsRelation.attach(Downgraded).get()
        const depositDestiny = depositsRel.filter(it => it.id === dataDeposit.depositIdDestiny?.toString())
        const depositOrigin = depositsRel.filter(it => it.id === dataDeposit.depositIdOrigin?.toString())

        const postData = {
            depositsTransactions: {
                depositIdDestiny: dataDeposit.depositIdDestiny,
                depositDestiny: depositDestiny[0].descricao,
                depositIdOrigin: dataDeposit.depositIdOrigin,
                depositOrigin: depositOrigin[0].descricao
            }
        }

        console.log(postData)
        
        axs.put<any>(`/fulfillment/${props.itemId}/transferStock`, postData).then((r: any) => {
            if(r.status === 200){
                showStockModal.set(false);
                showButtons.set(false);
            }
        })
    }

    const handleDownloadTags = (evt: React.BaseSyntheticEvent)=>{
        evt.preventDefault();
        const aux_data = data.attach(Downgraded).get();
        const products = productsData.attach(Downgraded).get()
        products.forEach((item: any)=>{
            let idxP = aux_data.findIndex((data_item: any) => data_item._id === item.idItem)
            if(idxP > -1){
                let printedSum = aux_data[idxP].printed + parseInt(item.qty);
                aux_data[idxP].printed = printedSum
                inputs[item.idItem].printed.set(printedSum)
            }
        })

        axs.post<any>(`/fulfillment/${props.itemId}/printeritems`, {products}).then((r: any) => {
            if(r.status === 200){
                showDescModal.set(false);
                showButtons.set(false);
                download(r.data, `${props.itemId}-etiquetasImpressao.txt`);
                data.set(aux_data);
                handleChangeData()
            }
        }).catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }
    
    const handleChangeCost = (evt: React.BaseSyntheticEvent) =>{
        if(rowsSelected.get().length > 0){
            const rowsToChangeCost: any[] = []
            data.attach(Downgraded).get().forEach((item: any)=>{
                if(rowsSelected.attach(Downgraded).get().includes(item._id)){
                    rowsToChangeCost.push({"id":item._id, "sku": item.sku})
                }
            })

            if(rowsToChangeCost.length > 0){
                socket.emit("fulfillment:putCost", {'id': props.itemId, "itens": rowsToChangeCost});
                toast.info("Verificando..")
            }else{
                toast.info("Não existem valores para serem alterados.")
            }

            // executePut(`/fulfillment/${props.itemId}/cost`, {}, 'putChangeCost');
        }else{
            toast.info("Não existem valores para serem alterados.")
        }
    }

    const handleExcelMl = async(evt: React.BaseSyntheticEvent)=>{
        axs.post<any>(`/fulfillment/${props.itemId}/exportexcelml`, null, {headers:{'content-type': 'application/vnd.ms-excel;charset=UTF-8', 'Authorization': `Bearer ${token}`}, responseType: 'blob'})
        .then((r: any) => {
            if(r.status === 200){
                download(r.data, `Envio-de-estoque-Full.xlsx`);
            }else{
                toast.error("Ocorreu um erro ao processar a solicitação.")
            }
        })
        .catch((err: any)=>{
            handleValidateStatus(err.response)
        })
    }

    const options = {
        palette:{
          tableHeader: {
            main: '#F7CE3E',
            dark: '#ac902b',
            light: '#f8d764',
            contrastText: '#000'
          },
        },
        alternate: true,
        selection: (!dataShipping.completed.get()),
        rowStyle: (rowData: any) => {
            let row_class = '';
            if(rowData.finished) {
                row_class = "AllShipments-alternate-color-finished"
            }        
          return row_class
        },
    }

    const columns = [{
        headerName: "SKU",
        field: "sku",
        type: "string",
        hide: checkVisibility('sku'),
        headerAlign: 'center',
        flex: 1.3,
        minWidth: 130,
        renderCell: (rowData: any) =>{
            let sku_value = rowData.row.sku !== undefined ? {label: rowData.row.product, sku: rowData.row.sku, cost: undefined} : {label: "", sku: "", cost: undefined}
          return(
            <InputMainContainer>
                <div className="input-container">
                    <Autocomplete
                        id={`skus-relation-${rowData.row._id}`}
                        options={props.blingProducts}
                        value={sku_value}
                        isOptionEqualToValue={(option, value) => value.sku !== "" ? option.sku === value.sku : value.sku === ""}
                        getOptionLabel={(option: any) => `${option.sku} - ${option.label}`}
                        disabled={dataShipping.completed.get() || rowData.row.finished}
                        disableClearable
                        renderInput={(params) => <TextField variant="standard" {...params} placeholder="SKU" />}
                        renderOption={(props, option) => (
                            <li {...props}>
                              {`${option.label}`}
                            </li>
                        )}
                        PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                        onChange={async(evt: React.BaseSyntheticEvent, value: any)=> {
                                const idx = props.allSKUS.findIndex((item: any) => item.sku === value.sku)
                                if(idx > -1){
                                    const values = [
                                        {
                                            "field": "sku",
                                            "value": value.sku
                                        },
                                        {
                                            "field": "product",
                                            "value": props.allSKUS[idx].title
                                        },
                                        {
                                            "field": "cost_product",
                                            "value": props.allSKUS[idx].cost
                                        },
                                        {
                                            "field": "brand",
                                            "value": props.allSKUS[idx].brand
                                        },
                                        {
                                            "field": "origin",
                                            "value": props.allSKUS[idx].origin
                                        },
                                        {
                                            "field": "composition",
                                            "value": props.allSKUS[idx].composition
                                        },
                                        {
                                            "field": "gross_weight",
                                            "value": props.allSKUS[idx].gross_weight
                                        },
                                        {
                                            "field": "cubic_meter",
                                            "value": props.allSKUS[idx].cubic_meter
                                        },
                                        {
                                            "field": "category_bling",
                                            "value": props.allSKUS[idx].category
                                        },
                                    ]
                                    socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": values, "idItem": rowData.row._id, ref: "sku"});
                                }
                        }}
                    />
                </div>
            </InputMainContainer>
          )
        }
      },
    {
        headerName: "Produto",
        field: "product",
        type: "string",
        hide: checkVisibility('product'),
        headerAlign: 'center',
        flex: 3,
        minWidth: 300,
        renderCell: (rowData: any) =>{
            return(
                <Tippy content={rowData.row.product}>
                    <main style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        <span title={rowData.row.product}>{rowData.row.product}</span>
                    </main>
                </Tippy>
            )
        }
    },
    {
        headerName: "Tags",
        field: "tags",
        type: "selection",
        hide: checkVisibility('composition'),
        headerAlign: 'center',
        flex: 0.8,
        minWidth: 80,
        sortable:false,
        filterOperators: [{
            value: 'contains',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
              const selected_values = filterItem.value ? filterItem.value.map((item: any)=> item.title) : [];
              if (filterItem.value == null || selected_values.length === 0) {
                return null;
              }
        
              return ({ value }:{ value: any }): boolean => {
                if (typeof value === 'object') {
                  return selected_values.every((item: any)=> value.includes(item))
                }
                return selected_values.every((item: any)=> value.includes(item))
              };
            },
            // InputComponent: GridFilterInputValue,
            InputComponent: CheckboxesTags,
            InputComponentProps: { 
                type: 'singleSelect', 
                options: [
                    {title: 'Composição (Sim)'}, 
                    {title: 'Composição (Não)'}, 
                    {title: 'Nota Fiscal Dupla (Sim)'}, 
                    {title: 'Nota Fiscal Dupla (Não)'}, 
                ]},
          }],
          valueOptions: [
            'Composição (Sim)', 'Composição (Não)', 'Nota Fiscal Dupla (Sim)', 'Nota Fiscal Dupla (Não)' 
          ],
        renderCell: (rowData: any) => {
            const tagColors: any = {
              'tags': 
              [
                  
                {'title': `Composição: ${rowData.row.composition ? 'Sim':'Não'}`, 'value': <GoAlert className="icon"/>, 'color': (rowData.row.composition ? '#ED4C67' : '#a5b1c2')},
                {
                    'title': `NF Dupla: ${rowData.row.quantity_shipped > rowData.row.stockAllDetail.PR && rowData.row.stockAllDetail.PR > 0 ? (rowData.row.quantity_shipped > rowData.row.stockAllDetail.SC && rowData.row.stockAllDetail.SC > 0 ? "Sim" : 'Não') : 'Não'}`,
                    'value': <FaCheckDouble className="icon"/>, 
                    'color': (rowData.row.quantity_shipped > rowData.row.stockAllDetail.PR && rowData.row.stockAllDetail.PR > 0 ? (rowData.row.quantity_shipped > rowData.row.stockAllDetail.SC && rowData.row.stockAllDetail.SC > 0 ? "#B33771" : '#a5b1c2') : '#a5b1c2')
                },
              ]
            };
            return (
              <div className="tags-container" style={{marginTop: 0, flexWrap: 'nowrap', display: 'flex', gap: '5px'}}>
                {
                  Object.values(tagColors.tags).map((item: any, index: any)=>{
                    return (
                        <Tippy key={index} content={item.title} maxWidth={'25rem'}>
                            <span className="tag-small" style={{color: item.color}}>{item.value}</span>
                        </Tippy>
                    )
                  })
                }
      
              </div>
            )
        }
    },
    {
        headerName: "Id Anúncio",
        field: "mlb_id",
        type: "string",
        hide: checkVisibility('mlb_id'),
        headerAlign: 'center',
        flex: 1.5,
        minWidth: 150,
        renderCell: (rowData: any) =>{
          return(
            <InputMainContainer>
                <div className="input-container">
                    <Autocomplete
                        key={rowData.row.mlb_id}
                        id={`mlbs-relation-${rowData.row._id}`}
                        options={props.mlbs}
                        className={`mlbs-${rowData.row._id}`}
                        value={rowData.row.mlb_id}
                        isOptionEqualToValue={(option, value) => option.sku === value.sku}
                        disabled={dataShipping.completed.get() || rowData.row.finished}
                        disableClearable
                        renderInput={(params) => <TextField variant="standard" {...params} placeholder="MLB" />}
                        renderOption={(props, option) => (
                            <li {...props}>
                              {option}
                            </li>
                        )}
                        PopperComponent={(props)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                        onChange={async(evt: React.BaseSyntheticEvent, value: any)=> {
                            const variationsArr  = props.mlbVariations.filter((item: any)=> item._id === value)
                            
                            if(variationsArr[0].variations.length === 1){ // Caso possua somente 1 variação é setado a variação
                                const values = [
                                    {
                                        "field": "mlb_id",
                                        "value": value
                                    },
                                    {
                                        "field": "variation_id",
                                        "value": variationsArr[0].variations[0].variation_id ? variationsArr[0].variations[0].variation_id : null
                                    },
                                    {
                                        "field": "variation",
                                        "value": variationsArr[0].variations[0].variation_name ? variationsArr[0].variations[0].variation_name : ""
                                    },
                                    {
                                        "field": "inventory_id",
                                        "value": variationsArr[0].variations[0].inventory_id ? variationsArr[0].variations[0].inventory_id : null
                                    },
                                    {
                                        "field": "marketplace_fee",
                                        "value": variationsArr[0].variations[0].marketplace_fee ? variationsArr[0].variations[0].marketplace_fee : null
                                    },
                                    {
                                        "field": "cost_shipping_fee",
                                        "value": variationsArr[0].variations[0].cost_shipping ? variationsArr[0].variations[0].cost_shipping : null
                                    },
                                    {
                                        "field": "tax",
                                        "value": variationsArr[0].variations[0].tax ? variationsArr[0].variations[0].tax : null
                                    },
                                ]
                                socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": values, "idItem": rowData.row._id, ref: "mlb"});
                            }else{ // Caso possua mais de 1 variação
                                const values = [
                                    {
                                        "field": "mlb_id",
                                        "value": value
                                    },
                                    {
                                        "field": "variation_id",
                                        "value": ''
                                    },
                                    {
                                        "field": "variation",
                                        "value": ''
                                    },
                                    {
                                        "field": "inventory_id",
                                        "value": ''
                                    },
                                ]
                                socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": values, "idItem": rowData.row._id, ref: "mlb"});
                            }
                        }}
                    />
                </div>
            </InputMainContainer>
          )
        }
    },
    {
        headerName: "Variação",
        field: "variation_id",
        type: "string",
        hide: checkVisibility('variation_id'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 130,
        renderCell: (rowData: any) =>{
            let variationsOptions: any = [];
            let inventoryIds: any = {};
            if(props.mlbVariations){
                const variationsArr = props.mlbVariations.filter((item: any)=> item._id === rowData.row.mlb_id);
                if(variationsArr.length > 0){
                    for(let variation of variationsArr[0].variations){
                        const variation_id = variation.variation_id !== null ? variation.variation_id : 'N/A';
                        const variation_name = variation.variation_name !== null ? variation.variation_name : "N/A"
                        variationsOptions.push({label: variation_name, id: variation_id})
                        inventoryIds[variation.variation_id] = [variation.variation_name, variation.inventory_id, variation.marketplace_fee, variation.cost_shipping, variation.tax];
                    }
                }
            }
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <Autocomplete
                            id={`variation-relation-${rowData.row._id}`}
                            
                            options={variationsOptions}
                            value={{label: (variationsOptions.length === 1 ? (variationsOptions[0].id !== null ? (rowData.row.variation_id !== null ? rowData.row.variation ? rowData.row.variation : "N/A" : "N/A") : "N/A") : (rowData.row.variation_id !== null ? rowData.row.variation ? rowData.row.variation : "" : "")), id: rowData.row.variation_id}}
                            getOptionLabel={(option: any) => option.label}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            disableClearable
                            renderInput={(params) => <TextField variant="standard" {...params} placeholder="Variação" />}
                            renderOption={(props, option) => (
                                <li {...props}>
                                  {option.label}
                                </li>
                            )}
                            PopperComponent={(props: any)=> <StyledPopper {...props} style={{ width: "fit-content", padding: "1rem",display: "flex", flexDirection: "column", gap: '1rem' }} placement='bottom-start' />}
                            onChange={async(evt: React.BaseSyntheticEvent, value: any)=> {                                
                                    if(inventoryIds[value.id] !== undefined){
                                        const values = [
                                            {
                                                "field": "variation_id",
                                                "value": value.id
                                            },
                                            {
                                                "field": "variation",
                                                "value": inventoryIds[value.id][0]
                                            },
                                            {
                                                "field": "inventory_id",
                                                "value": inventoryIds[value.id][1]
                                            },
                                            {
                                                "field": "marketplace_fee",
                                                "value": inventoryIds[value.id][2]
                                            },
                                            {
                                                "field": "cost_shipping_fee",
                                                "value": inventoryIds[value.id][3]
                                            },
                                            {
                                                "field": "tax",
                                                "value": inventoryIds[value.id][4]
                                            },
                                        ]
                                        socket.emit("fulfillment:putItem", {'id': props.itemId, "fields": values, "idItem": rowData.row._id, ref: "variation"});
    
                                    }else{
                                        toast.info("O valor do campo Variação deve pertencer à lista informada.")
                                    }
                            }}
                        />
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "Inventory ID",
        field: "inventory_id",
        type: "string",
        hide: checkVisibility('inventory_id'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            const inventory_id = rowData.row.inventory_id ? rowData.row.inventory_id : "N/A"
            return(
                <span title={inventory_id}>{inventory_id}</span>
            )
        }
    },
    {
        headerName: "Estoque", 
        field: "stockAll", 
        type: 'number',
        hide: checkVisibility('stockAll'),
        headerAlign: 'center',
        flex: 0.5,
        minWidth: 80,
        renderCell: (rowData: any) => {
            return (
                <Tippy 
                content={Object.entries(rowData.row.stockAllDetail).map((item: any, index: any)=>{
                    return(
                    <div key={index}>{`${item[0]}: ${item[1]}`}</div>
                    )
                })}
                >
                    <div style={{width: 'fit-content'}}>{rowData.row.stockAll}</div>
                </Tippy>
            )
        }
    },
    {
        headerName: "Qtd. Recomendada",
        renderHeader: (params: any) => (
            <Tippy content={<div>Quantidade Recomendada</div>} maxWidth={'40rem'}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} className="MuiDataGrid-columnHeaderTitle">Qtd. Recomendada</div></Tippy>
        ),
        field: "quantity_recomended",
        type: "number",
        hide: checkVisibility('quantity_recomended'),
        headerAlign: 'center',
        flex: 0.8,
        minWidth: 80,
        renderCell: (rowData: any) =>{
            const qtdRecommended = Math.round(rowData.row.quantity_sku_recomended);
            return(
                <Tippy content={<div style={{display: 'flex', gap: '1rem'}}>Quantidade Total Sku Recomendada: {qtdRecommended}</div>} interactive={true} maxWidth={'none'}>
                    <span>{rowData.row.quantity_recomended}</span>
                </Tippy>
            )
          }
    },
    {
        headerName: "Qtd. Analista",
        renderHeader: (params: any) => (
            <Tippy content={<div>Quantidade Analista</div>} maxWidth={'40rem'}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} className="MuiDataGrid-columnHeaderTitle">Qtd. Analista</div></Tippy>
        ),
        field: "quantity_analyst",
        type: "number",
        hide: checkVisibility('quantity_analyst'),
        headerAlign: 'center',
        flex: 0.8,
        minWidth: 80,
    },
    {
        headerName: "Qtd. Liberada",
        renderHeader: (params: any) => (
            <Tippy content={<div>Quantidade Liberada</div>} maxWidth={'40rem'}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} className="MuiDataGrid-columnHeaderTitle">Qtd. Liberada</div></Tippy>
        ),
        field: "quantity_released",
        type: "number",
        hide: checkVisibility('quantity_released'),
        headerAlign: 'center',
        flex: 0.8,
        minWidth: 80,
        renderCell: (rowData: any) =>{
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <input
                            tabIndex={1}
                            type="number"
                            className="inputTable"
                            name="quantity_released"
                            id={`quantity_released-${rowData.row._id}`}
                            required={true}
                            value={inputs[rowData.row._id].get() ? inputs[rowData.row._id].quantity_released.get() : 0}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            key={rowData.row.quantity_released}
                            onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                            onChange={(evt: any)=> {
                                evt.persist();
                                clearTimeout(filterTimeout.current)
                                filterTimeout.current = setTimeout(() => {
                                    handleCellEdit(evt.target.value, rowData.row, "quantity_released")
                                }, 1500)
                                inputs[rowData.row._id].quantity_released.set(evt.target.value)
                            }}
                        />
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "Qtd. Enviada",
        renderHeader: (params: any) => (
            <Tippy content={<div>Quantidade Enviada</div>} maxWidth={'40rem'}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} className="MuiDataGrid-columnHeaderTitle">Qtd. Enviada</div></Tippy>
        ),
        field: "quantity_shipped",
        type: "number",
        hide: checkVisibility('quantity_shipped'),
        headerAlign: 'center',
        flex: 0.8,
        minWidth: 80,
        renderCell: (rowData: any) =>{
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <input
                            key={rowData.row.quantity_shipped}
                            tabIndex={2}
                            type="number"
                            className="inputTable"
                            name="quantity_shipped"
                            id={`quantity_shipped-${rowData.row._id}`}
                            required={true}
                            value={inputs[rowData.row._id].quantity_shipped.get()}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                            onChange={(evt: any)=> {
                                evt.persist();
                                clearTimeout(filterTimeout.current)
                                filterTimeout.current = setTimeout(() => {
                                    handleCellEdit(evt.target.value, rowData.row, "quantity_shipped")
                                }, 1500)
                                inputs[rowData.row._id].quantity_shipped.set(parseFloat(evt.target.value))
                            }}
                        />
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "Custo",
        renderHeader: (params: any) => (
            <Tippy content={<div>Custo</div>} maxWidth={'40rem'}><div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} className="MuiDataGrid-columnHeaderTitle">Custo</div></Tippy>
        ),
        field: "cost_product",
        type: "number",
        hide: checkVisibility('cost_product'),
        headerAlign: 'center',
        flex: 1.1,
        minWidth: 110,
        renderCell: (rowData: any) =>{
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <input
                            tabIndex={3}
                            key={rowData.row.cost_product}
                            type="number"
                            className="inputTable money"
                            name="cost_product"
                            id={`cost_product-${rowData.row._id}`}
                            required={true}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            value={inputs[rowData.row._id].cost_product.get()}
                            onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                            onChange={async(evt: any)=> {
                                evt.persist()
                                clearTimeout(filterTimeout.current)
                                if(evt.target.value <= 0){
                                    toast.info("O valor do campo Custo não pode ser menor que 1")
                                    return;
                                }

                                filterTimeout.current = setTimeout(() => {
                                    handleCellEdit(evt.target.value, rowData.row, "cost_product")
                                }, 1500)
                                inputs[rowData.row._id].cost_product.set(evt.target.value)
                            }}
                        />
                        <label className="inputTableLabelMoney" htmlFor={`cost_product-${rowData.row._id}`}>R$</label>
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "OBS",
        field: "observation",
        type: "string",
        hide: checkVisibility('observation'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <input
                            tabIndex={-1}
                            type="text"
                            className="inputTable"
                            name="observation"
                            id={`observation-${rowData.row._id}`}
                            required={true}
                            key={rowData.row.observation}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            value={inputs[rowData.row._id].observation.get()}
                            onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                            onChange={async(evt: any)=> {
                                evt.persist();
                                clearTimeout(filterTimeout.current)
                                filterTimeout.current = setTimeout(() => {
                                    handleCellEdit(evt.target.value, rowData.row, "observation")
                                }, 1500)
                                inputs[rowData.row._id].observation.set(evt.target.value)
                            }}
                        />
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "N° NF",
        field: "number_nf",
        type: "string",
        hide: checkVisibility('number_nf'),
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (rowData: any) =>{
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <input
                            key={rowData.row.number_nf}
                            tabIndex={-1}
                            type="string"
                            className="inputTable"
                            name="number_nf"
                            id={`number_nf-${rowData.row._id}`}
                            required={true}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            value={inputs[rowData.row._id].number_nf.get()}
                            onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                            onChange={async(evt: any)=> {
                                evt.persist()
                                clearTimeout(filterTimeout.current)
                                filterTimeout.current = setTimeout(() => {
                                    handleCellEdit(evt.target.value, rowData.row, "number_nf")
                                }, 1500)
                                inputs[rowData.row._id].number_nf.set(evt.target.value)
                            }}
                        />
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "Impresso",
        field: "printed",
        type: "string",
        hide: checkVisibility('printed'),
        headerAlign: 'center',
        flex: 0.8,
        minWidth: 80,
        renderCell: (rowData: any) =>{
            return(
                <InputMainContainer>
                    <div className="input-container">
                        <input
                            tabIndex={-1}
                            key={rowData.row.printed}
                            type="number"
                            className="inputTable"
                            name="printed"
                            id={`printed-${rowData.row._id}`}
                            required={true}
                            value={inputs[rowData.row._id].printed.get()}
                            disabled={dataShipping.completed.get() || rowData.row.finished}
                            onFocus={(evt: React.BaseSyntheticEvent) => evt.target.select()}
                            onChange={async(evt: any)=> {
                                evt.persist()
                                clearTimeout(filterTimeout.current)

                                filterTimeout.current = setTimeout(() => {
                                    handleCellEdit(evt.target.value, rowData.row, "printed")
                                }, 1500)
                                inputs[rowData.row._id].printed.set(evt.target.value)
                            }}
                        />
                    </div>
                </InputMainContainer>
            )
        }
    },
    {
        headerName: "Finalizado",
        field: "finished",
        type: "boolean",
        disableColumnMenu: true,
        sortable: false,
        headerAlign: 'center',
        flex: 0.7,
        minWidth: 70,
        renderCell: (rowData: any) =>{
            return(
                <div className="switch-container">
                    <label className="switch">
                        <input 
                            type="checkbox" 
                            checked={inputs[rowData.row._id].finished.get()}
                            disabled={dataShipping.completed.get()}
                            onChange={(evt: React.BaseSyntheticEvent)=> handleItemFinished(evt,rowData.row._id)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            )
        }
    }
]



    return(
        <div className={`allShipmentsSection ${DarkMode ? 'dark-mode-allShipmentsSection' : ''}`}>
            {
                showTabsModal.get() === true && dataTabsModal !== undefined
                ?
                <BasicModal closeFunction={(evt: any)=> showTabsModal.set(false)} notCloseBind={true}>
                    <Paper style={{width: '90vw', height: '90%', padding: '1rem'}}>
                        <Tabs>
                            <TabList>
                                <Tab>
                                    <Badge sx={{'& .MuiBadge-badge':{fontSize: 'clamp(1rem, 1vw, 1.2rem)', right: '-8px'}}} showZero badgeContent={dataTabsModal.success_products.get().length} color="primary">
                                        <span>Produtos Com Sucesso</span>
                                    </Badge>
                                </Tab>
                                <Tab>
                                    <Badge sx={{'& .MuiBadge-badge':{fontSize: 'clamp(1rem, 1vw, 1.2rem)', right: '-8px'}}} showZero badgeContent={dataTabsModal.fail_products.get().length} color="primary">
                                        <span>Produtos Com Falha</span>
                                    </Badge>
                                </Tab>
                                <Tab>
                                    <Badge sx={{'& .MuiBadge-badge':{fontSize: 'clamp(1rem, 1vw, 1.2rem)', right: '-8px'}}} showZero badgeContent={dataTabsModal.revision.get().length} color="primary">
                                        <span>Produtos Para Revisão</span>
                                    </Badge>
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <VirtualizedGrid 
                                    rows={dataTabsModal.success_products.attach(Downgraded).get()}
                                    columns={[
                                        {
                                            headerName: "SKU",
                                            field: "sku",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                        },
                                        {
                                            headerName: "Nota Fiscal",
                                            field: "numberNf",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                        },
                                    ]}
                                    options={{...options, selection: false}}
                                    title="Produtos Com Sucesso"
                                    defaultSort={{field: 'sku', direction: 'asc'}}
                                />
                            </TabPanel>

                            <TabPanel>
                                <VirtualizedGrid 
                                    rows={dataTabsModal.fail_products.attach(Downgraded).get()}
                                    columns={[
                                        {
                                            headerName: "SKU",
                                            field: "sku",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                        },
                                        {
                                            headerName: "Mensagem",
                                            field: "message",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                        },
                                    ]}
                                    options={{...options, selection: false}}
                                    title="Produtos Com Falha"
                                    defaultSort={{field: 'sku', direction: 'asc'}}
                                />
                            </TabPanel>

                            <TabPanel>
                                <VirtualizedGrid 
                                    rows={dataTabsModal.revision.attach(Downgraded).get()}
                                    columns={[
                                        {
                                            headerName: "SKU",
                                            field: "sku",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                        },
                                        {
                                            headerName: "OBS",
                                            field: "obs",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                        },
                                        {
                                            headerName: "NFS",
                                            field: "nfs",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                            renderCell: (rowData: any) =>{
                                                return(
                                                    <Tippy content={
                                                        <ul style={{display: 'flex', flexDirection: 'row', padding: '1rem', gap: '1rem'}}>
                                                            {
                                                                rowData.row.nfs.map((item: any)=>{
                                                                    return(
                                                                        <li>{item}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    }>
                                                        <strong>Ver Detalhes</strong>
                                                    </Tippy>
                                                )
                                            }
                                        },
                                        {
                                            headerName: "Detalhes do Estoque",
                                            field: "stockDetail",
                                            type: "string",
                                            headerAlign: 'center',
                                            flex: 1,
                                            renderCell: (rowData: any) =>{
                                                return(
                                                    <Tippy content={
                                                        Object.entries(rowData.row.stockDetail).map((item: any)=>{
                                                            return(
                                                                <div style={{display: 'flex', flexDirection: 'row', padding: '1rem', gap: '1rem'}}>
                                                                    <strong>{item[0]}:</strong>
                                                                    <span>{item[1]}</span>
                                                                </div>
                                                            )
                                                        })
                                                    }>
                                                        <strong>Ver Detalhes</strong>
                                                    </Tippy>
                                                )
                                            }
                                        },
                                    ]}
                                    options={{...options, selection: false}}
                                    title="Produtos Para Revisão"
                                    defaultSort={{field: 'sku', direction: 'asc'}}
                                />
                            </TabPanel>
                        </Tabs>
                    </Paper>
                </BasicModal>
                : null
            }
            {
                showModal.get() === true
                ?<GridModal 
                    closeFunction={()=> showModal.set(false)} tableTitle="Custos Alterados" 
                    alternateColor="alternate"
                    options={modalOptions} 
                    columns={modalColumns} 
                    data={modalData.attach(Downgraded).get()} 
                    style={{width: '80%'}}
                    defaultSort={{field: 'total', direction: 'desc'}}
                />
                : null
            }

            {
                showDescModal.get() === true ?
                <BasicModal closeFunction={closeFunction}>
                    <form className="add-description-form-cpr" onSubmit={(evt: React.BaseSyntheticEvent)=> handleDownloadTags(evt)}>
                        <h3>{productsData.get().length} Produtos Selecionados:</h3>
                        <div className="input-container">
                            <div style={{padding: '1rem', overflowY:"scroll", maxHeight: '30rem'}}>
                                <table style={{width: '100%'}}>
                                    <thead>
                                    <tr>
                                        <th><strong>SKU:</strong></th>
                                        <th><strong>Produto: </strong></th>
                                        <th><strong>ID Anúncio: </strong></th>
                                        <th><strong>Qtd Imprimir: </strong></th>
                                        <th><strong>Remover: </strong></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.values(productsData.attach(Downgraded).get()).map((item: any, index: number)=>{
                                                return (
                                                    <tr key={index}>
                                                        <td><strong>{item.sku}</strong></td>
                                                        <td><strong title={item.title}>{item.title}</strong></td>
                                                        <td><strong>{item.mlb_id}</strong></td>
                                                        <td>
                                                            <TextField 
                                                                variant="standard" 
                                                                value={item.qty} 
                                                                onChange={(evt: React.BaseSyntheticEvent)=> handleEditItemModal(item.idItem, evt.target.value)}
                                                            />
                                                        </td>
                                                        <td><button type="button" className="remove-btn" onClick={()=>handleRemoveItemModal(item.idItem)}><FaMinus /></button></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* botão criado apenas para desabilitar o envio do form com a tecla enter */}
                        <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
    
                        <div className="input-main-container">
                            <button><FaDownload className="icon"/>Download das Etiquetas</button>
                        </div>
                    </form>
                </BasicModal>
                :
                null
            }

{
                showStockModal.get() === true ?
                <BasicModal closeFunction={closeFunction}>
                    <form className="add-description-form-cpr" onSubmit={(evt: React.BaseSyntheticEvent)=> handleTransferStock(evt)}>
                        <h3>Transferência de Estoque.</h3>
                        <div className="input-container">
                            <div style={{padding: '1rem', overflowY:"scroll", maxHeight: '30rem'}}>
                                <InputMainContainer>
                                    <SelectContainer
                                        label="Depósito de Origem"
                                        id="depositIdOrigin"
                                        name="depositIdOrigin"
                                        required={true}
                                        options={
                                            // {'data': [{'value': '', 'text': ''}, {'value': 'to_buy', 'text': 'A Comprar'}, {'value': 'buyed', 'text': 'Comprado'}, {'value': 'rejected', 'text': 'Rejeitado'}]},
                                            {'data': depositsRelation.get().map((it) => {
                                                return {'value': it.id, 'text': it.descricao}
                                            })
                                            }
                                        }
                                        value={undefined}
                                        onChange={(value: any)=> depositsTransactions.depositIdOrigin.set(value['depositIdOrigin'])}
                                    />
                                </InputMainContainer>
                                <InputMainContainer>
                                    <SelectContainer
                                        label="Depósito de Destino"
                                        id="depositIdDestiny"
                                        name="depositIdDestiny"
                                        required={true}
                                        options={
                                            // {'data': [{'value': '', 'text': ''}, {'value': 'to_buy', 'text': 'A Comprar'}, {'value': 'buyed', 'text': 'Comprado'}, {'value': 'rejected', 'text': 'Rejeitado'}]},
                                            {'data': depositsRelation.get().map((it) => {
                                                return {'value': it.id, 'text': it.descricao}
                                            })
                                            }
                                        }
                                        value={undefined}
                                        onChange={(value: any)=> depositsTransactions.depositIdDestiny.set(value['depositIdDestiny'])}
                                    />
                                </InputMainContainer>
                            </div>
                        </div>
                        {/* botão criado apenas para desabilitar o envio do form com a tecla enter */}
                        <button type="submit" disabled style={{display: 'none'}} aria-hidden="true"></button>
    
                        <div className="input-main-container">
                            <button>Confirmar</button>
                        </div>
                    </form>
                </BasicModal>
                :
                null
            }
            <ModalConfirm />
            {
                showButtons.get() ?
                <button 
                    className="floating_delete" 
                    onClick={(event: any) => showHideModal({show: true, title: "Gostaria de excluir os itens selecionados?", execute: (confirm: boolean)=> handleDelete(confirm)})}
                >
                    <MdDelete className="icon" />
                </button>
                :
                null
            }
            <main className='container-fluid' style={{padding: '0'}}>
                <TutorialButton url="https://www.youtube.com/watch?v=06UKldDuS_A" style={{margin: '1rem 0'}} />
                {
                    dataShipping.LOADED.get()?
                    <BasicForm style={{width: '100%'}} SubmitFunction={handleSubmit}>
                        <InputMainContainer>
                            <InputFilterContainer 
                                label="Descrição do Envio"
                                id="description"
                                class="description"
                                name="description"
                                value={dataShipping.description.get()} 
                                type="text" 
                                key='dataShippingvDescriptionInput'
                                required={true}
                                controlled={true}
                                disabled={dataShipping.completed.get()}
                                onChange={async(value: any)=> {
                                    //evt.persist()
                                    clearTimeout(filterTimeout.current)
                                    const processedData = {
                                        'id': props.itemId,
                                        "field": "description",
                                        "value": value["description"]
                                    }
                                    filterTimeout.current = setTimeout(() => {
                                        socket.emit("fulfillment:putFulfillment", processedData);
                                    }, 1500)
                                    dataShipping.description.set(value["description"])
                                }}
                            />

                            <DateFilterContainer
                                label="Agendamento de Entrega"
                                class="date"
                                id="date" 
                                name="date"
                                value={dataShipping.scheduling.get()}
                                type="date"
                                key='dataShippingSchedulingInput'
                                required={true}
                                disabled={dataShipping.completed.get()}
                                controlled={true}
                                onChange={(value: any)=> {
                                    clearTimeout(filterTimeout.current)
                                    const processedData = {
                                        'id': props.itemId,
                                        "field": "scheduling",
                                        "value": value["date"]
                                    }
                                    filterTimeout.current = setTimeout(() => {
                                        socket.emit("fulfillment:putFulfillment", processedData);
                                    }, 1500)
                                    dataShipping.scheduling.set(value["date"])
                                }}
                            />

                            <InputFilterContainer 
                                label="Qtd. Volumes do Agendamento"
                                id="volumesItens"
                                class="volumesItens"
                                name="volumesItens"
                                value={dataShipping.volumesQty.get()} 
                                type="text" 
                                key='dataShippingVolumesQtyInput'
                                required={true}
                                disabled={dataShipping.completed.get()}
                                onEnter={true}
                                controlled={true}
                                onChange={async(value: any)=> {
                                    //evt.persist()
                                    clearTimeout(filterTimeout.current)
                                    if(isNaN(parseInt(value["volumesItens"]))){
                                        toast.info("O campo Qtd. Volumes do Agendamento deve ser preenchido com valores numéricos");
                                        return
                                    }
                                    if(parseInt(value["volumesItens"]) <= 0){
                                        toast.info("O campo Qtd. Volumes do Agendamento não pode ser 0");
                                        return
                                    }
                                
                                    const processedData = {
                                        'id': props.itemId,
                                        "field": "volume",
                                        "value": parseInt(value["volumesItens"])
                                    }
                                    filterTimeout.current = setTimeout(() => {
                                        socket.emit("fulfillment:putFulfillment", processedData);
                                    }, 1500)
                                    dataShipping.volumesQty.set(parseInt(value["volumesItens"]))
                                }}
                            />

                            <InputFilterContainer 
                                label="Qtd. Itens do Agendamento"
                                id="schedulingItens"
                                class="schedulingItens"
                                name="schedulingItens"
                                value={dataShipping.schedulingQty.get()} 
                                type="text" 
                                key='dataShippingvSchedulingQtyInput'
                                required={true}
                                disabled={dataShipping.completed.get()}
                                onEnter={true}
                                controlled={true}
                                onChange={async(value: any)=> {
                                    //evt.persist()
                                    clearTimeout(filterTimeout.current)
                                    if(isNaN(parseInt(value["schedulingItens"]))){
                                        toast.info("O campo Qtd. Itens do Agendamento deve ser preenchido com valores numéricos");
                                        return
                                    }
                                    if(parseInt(value["schedulingItens"]) <= 0){
                                        toast.info("O campo Qtd. Itens do Agendamento não pode ser 0");
                                        return
                                    }
                                
                                    const processedData = {
                                        'id': props.itemId,
                                        "field": "quantity",
                                        "value": parseInt(value["schedulingItens"])
                                    }
                                    filterTimeout.current = setTimeout(() => {
                                        socket.emit("fulfillment:putFulfillment", processedData);
                                    }, 1500)
                                    dataShipping.schedulingQty.set(parseInt(value["schedulingItens"]))
                                }}
                            />

                            <InputMainContainer style={{width: '80%'}}>
                                <Button disabled={!showFinishBtn.get() ? true:false || dataShipping.completed.get()} variant="contained" color='error' sx={{fontSize: 'clamp(1.2rem, 1.2vw, 1.6rem)', fontFamily: "'Lato', sans-serif", width: '100%', display: 'flex', alignItems: 'center', '& .MuiButton-startIcon':{margin: '0'}}} type='submit'>
                                    Finalizar Etapa
                                </Button>
                            </InputMainContainer>
                        </InputMainContainer>
                    </BasicForm>
                    :
                    null
                }

                <div className="card-container">
                {/* <Card 
                    title="Produtos Alterados" 
                    content={adsChanged} 
                    lastMonth={0} percent={false} 
                    integer={false} style={{width: '40rem'}} simple={true} text={true} prefix=""
                    increment={true}
                /> */}
                    <Card 
                        title="Quantidade Total do Envio" content={dataShipping.schedulingQty.attach(Downgraded).get() || 0} lastMonth={0} percent={false} 
                        integer={false} style={{width: '25rem'}} text={true} simple={true}
                    />
                    <Card 
                        title="Peso do Envio" 
                        content=
                        {
                            <div>
                                <strong style={{color: (((parseFloat(dataCards.totalShippingWeight.get())/dataCards.maxShippingWeight.get())*100) >= 70 && ((parseFloat(dataCards.totalShippingWeight.get())/dataCards.maxShippingWeight.get())*100) < 85 ? "#EE5A24" : ((parseFloat(dataCards.totalShippingWeight.get())/dataCards.maxShippingWeight.get())*100) >= 85 ? "#EA2027" : "")}}>{dataCards.totalShippingWeight.get()}</strong> <strong>/ {dataCards.maxShippingWeight.get()} Kg</strong>
                            </div>
                        } 
                        lastMonth={0} percent={false} 
                        integer={false} style={{width: '25rem'}} simple={true} text={true}
                    />
                    <Card 
                        title="Metros Cúbicos do Envio" 
                        content={
                            <div>
                                <strong style={{color: (((parseFloat(dataCards.totalCubicMeters.get())/dataCards.maxCubicMeters.get())*100) >= 70 && ((parseFloat(dataCards.totalCubicMeters.get())/dataCards.maxCubicMeters.get())*100) < 85 ? "#EE5A24" : ((parseFloat(dataCards.totalCubicMeters.get())/dataCards.maxCubicMeters.get())*100) >= 85 ? "#EA2027" : "")}}>{dataCards.totalCubicMeters.get()}</strong><strong> / {dataCards.maxCubicMeters.get()} m³</strong>
                            </div>
                        } 
                        
                        lastMonth={0} percent={false} 
                        integer={false} style={{width: '25rem'}} simple={true} text={true}
                    />
                    <Card 
                        title="Quantidade Total a Enviar" content={dataCards.totalQtyToShip.attach(Downgraded).get()} lastMonth={0} percent={false} 
                        integer={true} style={{width: '25rem'}} simple={true}
                    />
                    <Card 
                        title="Quantidade Sobrando" content={dataCards.totalQtyAvailable.attach(Downgraded).get()} lastMonth={0} percent={false} 
                        integer={false} style={{width: '25rem'}} text={true} simple={true}
                        styleContent={{color: (dataCards.totalQtyAvailable.attach(Downgraded).get() < 0) ? "#EA2027" : ""}}
                    />
                    <Card 
                        title="Quantidade Permitida a Variar" content={`${dataCards.totalQtyVariable.attach(Downgraded).get()}%`} lastMonth={0} percent={false} 
                        integer={false} text={true} style={{width: '25rem'}} simple={true}
                        styleContent={{color: 
                        (dataCards.totalQtyVariable.get() < -10) ? "#EA2027" :
                        (dataCards.totalQtyVariable.get() >= -10 && dataCards.totalQtyVariable.get() <= 10 )? "#2ECC71" : 
                        (dataCards.totalQtyVariable.get() > 10 && dataCards.totalQtyVariable.get() <= 30) ? "#F79F1F" : 
                        (dataCards.totalQtyVariable.get() > 30) ? "#EA2027" : ""}}
                    />
                </div>
                <div className="addRow">
                    <input 
                        type="number" 
                        name="add-row" 
                        id="add-row" 
                        className="add-row-input" 
                        min={0} 
                        step={1} 
                        placeholder="Quantidade"
                        value={rowsToAdd.get()}
                        disabled={dataShipping.completed.get()}
                        onChange={(evt: React.BaseSyntheticEvent)=> rowsToAdd.set(evt.target.value)}

                    />
                    <button className='round' onClick={(evt: React.BaseSyntheticEvent)=> addRow(evt)} disabled={dataShipping.completed.get()}><FaPlus className="icon-small"/>Produtos</button>
                </div>
                <button type="button" onClick={() => {handlePrintTable()}} className="printBtn"><FaPrint className="icon-small" />Imprimir Tabela</button>
                <button type="button" disabled={!showButtons.get() ? true:false || dataShipping.completed.get()} onClick={(evt: React.BaseSyntheticEvent)=> handleChangeCost(evt)} className="printBtn"><FaEdit className="icon-small" />Atualização Custo</button>
                <button type="button" className="printBtn" disabled={!showButtons.get() ? true:false || dataShipping.completed.get()} onClick={(evt: React.BaseSyntheticEvent)=> handlePrint(evt)}><FaPrint className="icon-small" />Impressão Zebra</button>
                <button type="button" disabled={!showButtons.get() ? true:false || dataShipping.completed.get()} onClick={(evt: React.BaseSyntheticEvent)=> handleNF(evt)} className="printBtn"><FaStickyNote className="icon-small" />Enviar NFe</button>
                <button type="button" className="printBtn" onClick={(evt: React.BaseSyntheticEvent)=> handleExcelMl(evt)}><FaFileExcel className="icon-small" />Excel ML</button>
                <button type="button" className="printBtn" disabled={!showFinishBtn.get() ? true:false || dataShipping.completed.get()} onClick={(evt: React.BaseSyntheticEvent)=> showStockModal.set(true)}><FaExchangeAlt className="icon-small" />Estoque</button>

                {
                    data.get().length > 0 && inputs.get() !== undefined ?
                    <>
                        <VirtualizedGrid 
                            rows={data.attach(Downgraded).get()}
                            columns={columns}
                            options={options}
                            tableHeight={'auto'}
                            title="Relação dos Envios"
                            onSelectionDefault={(rows: any)=>  handleSelection(rows)}
                            defaultSort={{field: 'finished', direction: 'asc'}}
                        />
                        {
                            data.get().length >= 10 ?
                            <div className="addRow">
                                <input 
                                    type="number" 
                                    name="add-row-2" 
                                    id="add-row-2" 
                                    className="add-row-input" 
                                    min={0} 
                                    step={1} 
                                    placeholder="Quantidade"
                                    value={rowsToAdd.get()}
                                    onChange={(evt: React.BaseSyntheticEvent)=> rowsToAdd.set(evt.target.value)}
                                    disabled={dataShipping.completed.get()}
                                />
                                <button className='round' onClick={(evt: React.BaseSyntheticEvent)=> addRow(evt)} disabled={dataShipping.completed.get()}><FaPlus className="icon-small" />Produtos</button>
                            </div>
                            :
                            null
                        }
                    </>
                    : null
                }
            </main>
                    <div style={{height: '0', overflow: 'hidden'}}>
						<div ref={componentRef}>
						<h2 style={{margin: '5px'}}>Envio: {dataShipping.description.get()}</h2>
                        <table style={{width: '100%', pageBreakAfter: 'auto'}}>
                            <thead style={{borderBottom: '1px solid rgba(255,255,255, 0.6)', backgroundColor: "#F7CE3E", padding: '1rem', pageBreakAfter: 'auto'}}>
                                <tr style={{width: '100%', display: 'flex', pageBreakInside: 'avoid', pageBreakAfter: 'auto'}}>
                                    <th style={{padding: '1rem', width: '8rem'}}><strong style={{fontSize: '1.6rem'}}>Sep.</strong></th>
                                    <th style={{padding: '1rem', width: '8rem'}}><strong style={{fontSize: '1.6rem'}}>Etiq.</strong></th>
                                    <th style={{padding: '1rem', width: '10rem'}}><strong style={{fontSize: '1.6rem'}}>SKU</strong></th>
                                    <th style={{padding: '1rem', width: '20rem'}}><strong style={{fontSize: '1.6rem'}}>Cód. ML</strong></th>
                                    <th style={{padding: '1rem', width: '10rem'}}><strong style={{fontSize: '1.6rem'}}>QTD.</strong></th>
                                    <th style={{padding: '1rem', width: '100%'}}><strong style={{fontSize: '1.6rem'}}>Produto</strong></th>
                                </tr>
                            </thead>
                            <tbody style={{pageBreakAfter: 'auto'}}>
                                {
                                    itensToPrint.get().length > 0 ? 
                                    itensToPrint.attach(Downgraded).get().map((item: any, index: any)=>{
                                        return(
                                                <tr key={`${item._id}-print`}  style={{width: '100%', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? '#ffffff' : '#eeeeee', pageBreakInside: 'avoid', pageBreakAfter: 'auto', borderLeft: '1px solid black', borderRight: '1px solid black', borderTop: '1px solid black', borderBottom: index === itensToPrint.attach(Downgraded).get().length - 1 ? '1px solid black' : 'none'}}>
                                                    <td style={{padding: '1rem', width: '8rem'}}>
                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} />
                                                    </td>
                                                    <td style={{padding: '1rem', width: '8rem'}}>
                                                        <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }} />
                                                    </td>
                                                    <td style={{padding: '1rem', width: '10rem'}}>
                                                        <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>{item.sku}</strong>
                                                    </td>
                                                    <td style={{padding: '1rem', width: '20rem'}}>
                                                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                            {   
                                                                item.inventory_id.replaceAll(', ', ',').split(',').map((inventory_id: any, index:number)=>{
                                                                    return(
                                                                        <strong key={`${item._id}-print_inventory_id${index}`} style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{inventory_id}</strong>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                    <td style={{padding: '1rem', width: '10rem'}}>
                                                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                            {   
                                                                item.quantity_shipped.toString().replaceAll(', ', ',').split(',').map((quantity_shipped: any, index:number)=>{
                                                                    return(
                                                                        <strong key={`${item._id}-print_quantity_shipped${index}`} style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>{quantity_shipped}</strong>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                    <td style={{padding: '1rem', width: '100%'}}>
                                                        {
                                                            item.product && item.product.length > 70 ?
                                                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                    {item.product.slice(0, 70)}
                                                                </strong>
                                                                <strong style={{fontSize: "8px", textAlign: 'left', width: '100%', lineHeight: '1.1'}}>
                                                                    {item.product.slice(70, item.product.length)}
                                                                </strong>
                                                            </div>
                                                            :
                                                            <strong style={{fontSize: "8px", textAlign: 'left', width: '100%'}}>
                                                                {item.product}
                                                            </strong>
                                                        }
                                                    </td>
                                                </tr>
                                        )
                                    })
                                    :
                                    null
                                }
                            </tbody>
                        </table>
						</div>
                    </div>
        </div>
    )
    
}
